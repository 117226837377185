var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "grid-container",
    { staticClass: "text-center" },
    [
      _c("p", [_vm._v("Congratulations on completing your assessment!")]),
      _c("p", [
        _vm._v(
          "You may now view your assessment results by clicking the button below!"
        )
      ]),
      _c("p", [_vm._v("Thank you and we hope you enjoyed the experience!")]),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.viewResults } },
        [_vm._v("\n    View Results\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }