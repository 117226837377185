var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "grid-container",
    { staticClass: "sjt-container" },
    [
      _c("div", { staticClass: "information-bar flex justify-space-between" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("GameInfo")))]),
        _c("span", [
          _vm._v(_vm._s(_vm.$t("Question")) + ": "),
          _c("strong", [_vm._v(_vm._s(_vm.currentQuestionIndex + 1))]),
          _vm._v("/" + _vm._s(_vm.totalQuestionsCounter))
        ])
      ]),
      _c("div", { staticClass: "white-bubble" }, [
        _c("h5", [_vm._v(_vm._s(_vm.$t("Question")))]),
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.currentQuestion ? _vm.currentQuestion.questionText : ""
            )
          }
        })
      ]),
      _c(
        "el-table",
        {
          staticClass: "width-100 responses-table",
          attrs: { gutter: _vm.$gutter, data: _vm.options }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "optionText", label: _vm.$t("Response") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(scope.row.optionText) }
                    })
                  ]
                }
              }
            ])
          }),
          _c(
            "el-table-column",
            {
              attrs: { width: 90 },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled: _vm.worstChoiceID === scope.row.id,
                            label: scope.row.id
                          },
                          model: {
                            value: _vm.bestChoiceID,
                            callback: function($$v) {
                              _vm.bestChoiceID = $$v
                            },
                            expression: "bestChoiceID"
                          }
                        },
                        [_vm._v(" ")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content:
                          'You can only choose one option as "Best Choice"',
                        placement: "top"
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("BestChoice")))])]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "el-table-column",
            {
              attrs: { width: 90 },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            disabled: _vm.bestChoiceID === scope.row.id,
                            label: scope.row.id
                          },
                          model: {
                            value: _vm.worstChoiceID,
                            callback: function($$v) {
                              _vm.worstChoiceID = $$v
                            },
                            expression: "worstChoiceID"
                          }
                        },
                        [_vm._v(" ")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content:
                          'You can only choose one option as "Worst Choice"',
                        placement: "top"
                      }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("WorstChoice")))])]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSubmit,
              expression: "showSubmit"
            }
          ],
          staticClass: "submit-answer text-right width-100"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.$t("SubmitAnswer")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }