<template>
  <grid-container class="text-center">
    <p>Congratulations on completing your assessment!</p>

    <p>You may now view your assessment results by clicking the button below!</p>

    <p>Thank you and we hope you enjoyed the experience!</p>

    <el-button @click="viewResults" type="primary">
      View Results
    </el-button>
  </grid-container>
</template>

<script>
export default {
  name: 'Completed',
  computed: {
    siteUrl() {
      return process.env.VUE_APP_DASHBOARD_SJT_SCREEN
    }
  },
  methods: {
    viewResults() {
      window.location = this.siteUrl
    }
  }
}
</script>

<style scoped>
  .container p {
    margin: 24px 0;
  }
</style>
