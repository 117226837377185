<template>
  <grid-container class="sjt-container">
    <div class="information-bar flex justify-space-between">
      <span>{{ $t('GameInfo') }}</span>

      <span>{{ $t('Question') }}: <strong>{{ currentQuestionIndex + 1 }}</strong>/{{ totalQuestionsCounter }}</span>
    </div>

    <div class="white-bubble">
      <h5>{{ $t('Question') }}</h5>
      <span v-html="currentQuestion ? currentQuestion.questionText : ''"></span>
    </div>

    <el-table :gutter="$gutter" :data="options" class="width-100 responses-table">
      <el-table-column prop="optionText" :label="$t('Response')">
        <template slot-scope="scope">
          <span v-html="scope.row.optionText"></span>
        </template>
      </el-table-column>
      <el-table-column :width="90">
        <template slot="header">
          <el-tooltip effect="dark" content='You can only choose one option as "Best Choice"' placement="top">
            <span>{{ $t('BestChoice') }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-radio :disabled="worstChoiceID === scope.row.id" v-model="bestChoiceID" :label="scope.row.id">&nbsp;</el-radio>
        </template>
      </el-table-column>
      <el-table-column :width="90">
        <template slot="header">
          <el-tooltip effect="dark" content='You can only choose one option as "Worst Choice"' placement="top">
            <span>{{ $t('WorstChoice') }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-radio :disabled="bestChoiceID === scope.row.id" v-model="worstChoiceID" :label="scope.row.id">&nbsp;</el-radio>
        </template>
      </el-table-column>
    </el-table>

    <div v-show="showSubmit" class="submit-answer text-right width-100">
      <el-button type="primary" @click="submit">{{ $t('SubmitAnswer') }}</el-button>
    </div>
  </grid-container>
</template>

<script>
import axios from '@/axios/axios'

export default {
  name: 'SJT',
  computed: {
    gameplayProfile() {
      return this.$store.getters.gameplayProfile
    },

    softSkills() {
      return this.$store.getters.softSkills
    },

    softSkillsInvited() {
      return this.$store.getters.softSkillsInvited
    },

    justFinishedInvitation() {
      return this.$store.getters.justFinishedInvitation
    },

    isCompleted() {
      return this.$store.getters.isCompleted
    },

    totalQuestionsCounter() {
      return this.$store.getters.totalQuestionsCounter
    },

    currentQuestion() {
      let currentQuestion = null

      for (let softSkillIndex = 0; softSkillIndex < this.softSkills.length; softSkillIndex++) {
        const softSkill = this.softSkills[softSkillIndex]

        if (softSkill.status !== 'FINISHED') {
          for (let questionIndex = 0; questionIndex < softSkill.questions.length; questionIndex++) {
            const question = softSkill.questions[questionIndex]

            if (!question.answered) {
              currentQuestion = question
              break
            }
          }
          break
        }
      }

      return currentQuestion
    },

    softSkillIndex() {
      return this.$store.getters.softSkillIndex
    },

    currentQuestionIndex() {
      let currentQuestionIndex = 0

      for (let softSkillIndex = 0; softSkillIndex < this.softSkills.length; softSkillIndex++) {
        const softSkill = this.softSkills[softSkillIndex]

        if (softSkill.status !== 'FINISHED') {
          for (let questionIndex = 0; questionIndex < softSkill.questions.length; questionIndex++) {
            const question = softSkill.questions[questionIndex]

            if (!question.answered) {
              currentQuestionIndex = softSkillIndex * 4 + questionIndex
              break
            }
          }
          break
        }
      }

      return currentQuestionIndex
    },

    options() {
      return this.currentQuestion ? this.shuffle(this.currentQuestion.options) : []
    },

    showSubmit() {
      return this.bestChoiceID && this.worstChoiceID
    }
  },
  data() {
    return {
      bestChoiceID: null,
      worstChoiceID: null,
      questionStartTime: Date.now()
    }
  },
  methods: {
    shuffle: function(arr) {
      for (var i = arr.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1))
        var temp = arr[i]
        arr[i] = arr[j]
        arr[j] = temp
      }

      return arr
    },

    submit() {
      const msec = Date.now() - this.questionStartTime
      const questionSecondsTaken = Math.round(msec / 1000)

      axios.post('game/answer', {
        playerResultId: this.$store.getters.playerResultId,
        bestOptionSelectedId: this.bestChoiceID,
        worstOptionSelectedId: this.worstChoiceID,
        questionSecondsTaken: questionSecondsTaken,
        optionSecondsTaken: 0
      })
        .then(res => {
          this.setupNextQuestion()
        })
    },

    setupNextQuestion() {
      // Mark current q as complete
      this.currentQuestion.answered = true

      // If this is the last question of the skill
      if (!this.currentQuestion) {
        // Mark the skill completed
        this.softSkills[this.softSkillIndex].status = 'FINISHED'

        if (this.justFinishedInvitation) {
          this.$store.dispatch('setGameComplete', {
            playerResultId: this.$store.getters.playerResultId,
            softSkillsInvited: this.softSkillsInvited
          })
          this.$router.replace('/sjt/completed/invitation')
        }

        // If game was completed
        if (this.isCompleted) {
          this.$store.dispatch('setGameComplete', {
            playerResultId: this.$store.getters.playerResultId,
            softSkillsInvited: this.softSkills
          })
          this.$router.replace('/sjt/completed')
        }
      }

      this.bestChoiceID = null
      this.worstChoiceID = null
      this.questionStartTime = Date.now()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~vars';
  @import '~mixins';

  .information-bar {
    font-size: $-font-size-s;
    font-style: italic;
    color: $-font-color-secondary;
  }

  .white-bubble {
    padding: $-grid-gutter $-padding-unit;
    margin: $-grid-gutter 0;
    background-color: $-color-white;
    border-radius: $-border-radius;
    box-shadow: 0 0 18px 4px rgba(144, 164, 183, 0.22);
    z-index: 1;
  }

  .responses-table {
    margin: ($-grid-gutter * 2) 0;
    box-shadow: 0 0 18px 4px rgba(144, 164, 183, 0.22);

    @include res('xs') {
      font-size: $-font-size-s;
    }
  }

  .submit-answer {
    margin: $-grid-gutter 0;
  }
</style>
