var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "grid-container",
    { staticClass: "sjt-container" },
    [
      _c("p", [
        _vm._v(
          "Our online Soft Skills Assessment Tool provides an analysis of your strengths and areas of improvement across multiple dimensions."
        )
      ]),
      _c("p", [
        _vm._v(
          "Follow the instructions below to take our self-administering assessment to analyze your Soft Skills and begin to unlock your potential."
        )
      ]),
      _c("br"),
      _c("h5", [
        _c("u", [
          _vm._v(
            "Please keep a few things in mind before you begin the assessment:"
          )
        ])
      ]),
      _c("ul", [
        _c("li", [
          _vm._v("We ask that you take your time in answering the questions.")
        ]),
        _c("li", [
          _vm._v(
            "Try to keep distractions at a minimum while undergoing the assessment; such as closing all programs, including email."
          )
        ]),
        _c("li", [
          _vm._v(
            "The average duration of the assessment is approximately 5 minutes for each skill (4 Questions per Skill)"
          )
        ]),
        _c("li", [
          _vm._v(
            "Even though there is no time limit, try answering as spontaneously as possible. You may only answer each question ONCE and you cannot go “Back” to change your answer once you have submitted your response."
          )
        ]),
        _c("li", [
          _vm._v(
            "You will need a stable internet connection to take part in the assessment."
          )
        ]),
        _c("li", [
          _vm._v(
            "The assessment has an autosave function so you can stop at any point and continue where you left off at a later date. This feature is also helpful for instances of temperamental internet connections."
          )
        ])
      ]),
      _c("br"),
      _c("h5", [_c("u", [_vm._v("During the Assessment:")])]),
      _c("p", [
        _vm._v(
          "As part of the assessment process, you will be provided with a scenario or dilemma you need to resolve. In order to provide your response and move on to the next scenario; you will be required to provide "
        ),
        _c("u", [_c("b", [_vm._v("two responses")])]),
        _vm._v(" for each question.")
      ]),
      _c("p", [
        _vm._v(
          "Specifically, you will be called to label which question you believe is the “Best Choice” to solve the problem - as well as indicate what you feel is the “Worst Choice” to solve the problem. Once you submit your two responses, you may proceed onto the next scenario."
        )
      ]),
      _c("br"),
      _c("h5", [_c("u", [_vm._v("Support")])]),
      _c("p", [
        _vm._v("If you have any questions, please contact us at "),
        _c(
          "a",
          { staticClass: "link", attrs: { href: "mailto:info@owiwi.co.uk" } },
          [_vm._v("info@owiwi.co.uk")]
        ),
        _vm._v(" and we will be happy to help!")
      ]),
      _c("p", [
        _c("b", [
          _vm._v(
            "To begin the exam, click on “" +
              _vm._s(_vm.$t("TakeTheTest")) +
              "” below."
          )
        ])
      ]),
      _c("br"),
      _c("p", [_c("b", [_vm._v("Best of Luck!")])]),
      _c("br"),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.startTest } },
        [_vm._v("\n    " + _vm._s(_vm.$t("TakeTheTest")) + "\n  ")]
      ),
      _c(
        "ElDialog",
        {
          attrs: {
            "append-to-body": "",
            title: "Select Language",
            "custom-class": "dialog-size-550",
            visible: _vm.selectLang
          },
          on: {
            "update:visible": function($event) {
              _vm.selectLang = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.language,
                    callback: function($$v) {
                      _vm.language = $$v
                    },
                    expression: "language"
                  }
                },
                [
                  _c("el-radio-button", { attrs: { label: "en" } }, [
                    _vm._v("English")
                  ]),
                  _c("el-radio-button", { attrs: { label: "el" } }, [
                    _vm._v("Ελληνικά - Greek")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "ElButton",
                {
                  attrs: { type: "primary", disabled: !_vm.language },
                  on: {
                    click: function($event) {
                      return _vm.selectLanguage()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("TakeTheTest")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }